import * as firebase from 'firebase/app'
import constants from '@/constants.js'
import Vue from 'vue/dist/vue.js'

const initialState = () => ({
  //null initialize
  loadedInvestments: []
})

const state = {
  initialState: { ...initialState() },
  ...initialState()
}

const getters = {
  loadedInvestments(state) {
    return state.loadedInvestments
  },
  loadedInvestment(state) {
    return (investmentId) => {
      return state.loadedInvestments.find((investment) => {
        return investment.id === investmentId
      })
    }
  },
  loadedInvestmentsLiquidated(state) {
    return state.loadedInvestments.filter(investment => {
      return investment.isExited === true
    })
  },
  chartValueData(getters) {
    const prop = 'estValue'
    let temp = {}

    getters.loadedInvestments.forEach(
      elem => {
        if (!elem['isExited'] && !elem['isFailed']) {
          if (temp[elem['company_name']]) {
            temp[elem['company_name']] += +elem[prop]
          }
          else {
            temp[elem['company_name']] = +elem[prop]
          }
        }
      }
    )

    if (!temp) {
      temp['Add Investments'] = 1;
    }

    //Fix any floating point issues in rounding
    Object.entries(temp).forEach(([key, value]) => {
      temp[key] = +value.toFixed(2) //Round to 2, then convert back to num
    })

    let valueData = {}
    valueData.labels = Object.keys(temp)
    valueData.values = Object.values(temp)

    return valueData
  },
  chartSecurityData(getters) {
    const prop = 'security'
    let uniques = []

    getters.loadedInvestments.forEach(
      elem => {
        if (!elem['isExited'] && !elem['isFailed']) {
          if (uniques[elem[prop]]) {
            uniques[elem[prop]] += +elem['estValue']
          }
          else {
            uniques[elem[prop]] = +elem['estValue']
          }
        }
      }
    )
    if (!uniques) {
      uniques['Add Investments'] = 1;
    }

    //Fix any floating point issues in rounding
    Object.entries(uniques).forEach(([key, value]) => {
      uniques[key] = +value.toFixed(2) //Round to 2, then convert back to num
    })

    let securityData = {}
    securityData.labels = Object.keys(uniques)
    securityData.values = Object.values(uniques)
    return securityData
  }
}

const mutations = {
  updateCompanyName(state, payload) {
    let changedComps = state.loadedInvestments.filter(investment => {
      return investment.compID === payload.id
    })

    changedComps.forEach(thisCo => { //Loop through all investments in store and update company_name
      thisCo.company_name = payload.company_name
    })
  },
  setLoadedInvestments(state, payload) {
    state.loadedInvestments.push(...payload)
  },
  updateCustomField(state, payload) {
    //Get the current investment
    let curInvestment = state.loadedInvestments.find(investment => investment.id === payload.invID)
    //Get the current field
    let thisField = curInvestment.customFields.find(item => item.fieldID === payload.fieldID)

    if (payload.label) {
      thisField.label = payload.label
    }
    if (payload.value) {
      thisField.value = payload.value
    }
  },
  createField(state, payload) {
    let curInvestment = state.loadedInvestments.find(investment => investment.id === payload.invID)
    let newField = {
      [payload.fieldID]: {
        label: payload.label,
        value: payload.value
      }
    }

    curInvestment['customFields'] = Object.assign({}, curInvestment['customFields'], newField) //Not reactive
  },
  removeCustomField(state, payload) {
    let curInvestment = state.loadedInvestments.find((investment) => investment.id === payload.invID)
    Vue.delete(curInvestment['customFields'], payload.fieldID)
  },
  createInvestment(state, payload) {
    state.loadedInvestments.push(payload)
  },
  removeInvestment(state, id) {
    let index = state.loadedInvestments.map(item => item.id).indexOf(id)
    state.loadedInvestments.splice(index, 1)
  },
  resetInvestments(s) {
    const initial = { ...initialState() }
    Object.keys(initial).forEach(key => { s[key] = initial[key] })
  },
  updateInvestment(state, payload) {
    const investment = state.loadedInvestments.find(investment => {
      return investment.id === payload.id
    })

    Object.keys(payload).forEach(function (key, index) {
      if (key !== 'id') {
        if (key === 'dealTerms') {
          Object.assign(investment.dealTerms, payload.dealTerms) //Are these reactive? If not, do this.Obj = Obj.assign({},targ,src)
        } else if (key === 'dueDiligence') {
          Object.assign(investment.dueDiligence, payload.dueDiligence)
        } else if (key === 'taxes') {
          Object.assign(investment.taxes, payload.taxes)
        } else if (key === 'converted') {
          Object.assign(investment.converted, payload.converted)
        } else if (key === 'exitEvent') {
          Object.assign(investment.exitEvent, payload.exitEvent)
        } else if (key === 'customFields') {
          Object.keys(payload.customFields).forEach((k, i) => { //Loop through all customFields
            { Object.assign(investment.customFields[k], payload.customFields[k]) }
          })
        } else {
          investment[key] = payload[key]
        }
      }
    });
  }
}

const actions = {
  updateCustomField({ commit, getters }, payload) {
    commit('setStatus', 'loading', { root: true })
    commit('setLoading', true, { root: true })
    commit('clearError', null, { root: true })

    let compRef = firebase.firestore().collection('users').doc(getters.user.uid).collection('companies').doc(payload.compID)
    return compRef.set({
      investments: {
        [payload.invID]: {
          customFields: {
            [payload.fieldID]: {
              label: payload.label,
              value: payload.value
            }
          }
        }
      }
    }, { merge: true })
      .then(response => {
        commit('updateCustomField', payload)
        commit('setStatus', 'success', { root: true })
        commit('clearError', null, { root: true })
        commit('setLoading', false, { root: true })
      }).catch((err) => {
        console.log('Error ' + err)
        commit('setStatus', 'failure', { root: true })
        commit('setError', err.message, { root: true })
        commit('setLoading', false, { root: true })
      })
  },
  createCustomField({ commit, getters }, payload) {
    commit('setStatus', 'loading', { root: true })
    commit('setLoading', true, { root: true })
    commit('clearError', null, { root: true })
    let compRef = firebase.firestore().collection('users').doc(getters.user.uid).collection('companies').doc(payload.compID)

    return compRef.set({
      investments: {
        [payload.invID]: {
          customFields: {
            [payload.fieldID]: {
              label: payload.label,
              value: payload.value
            }
          }
        }
      }
    }, { merge: true })
      .then(response => {
        commit('createField', payload)
        commit('setStatus', 'success', { root: true })
        commit('clearError', null, { root: true })
        commit('setLoading', false, { root: true })
      }).catch((err) => {
        console.log('Error ' + err)
        commit('setStatus', 'failure', { root: true })
        commit('setError', err.message, { root: true })
        commit('setLoading', false, { root: true })
      })
  },
  deleteCustomField({ commit, getters }, payload) {
    commit('setStatus', 'loading', { root: true })
    commit('setLoading', true, { root: true })
    commit('clearError', null, { root: true })
    let compRef = firebase.firestore().collection('users').doc(getters.user.uid).collection('companies').doc(payload.compID)

    return compRef.update({
      ['investments.' + payload.invID + '.customFields.' + payload.fieldID]: firebase.firestore.FieldValue.delete()
    }).then(response => {
      commit('removeCustomField', payload)
      commit('setStatus', 'success', { root: true })
      commit('clearError', null, { root: true })
      commit('setLoading', false, { root: true })
    }).catch((err) => {
      console.log('Error ' + err)
      commit('setStatus', 'failure', { root: true })
      commit('setError', err.message, { root: true })
      commit('setLoading', false, { root: true })
    })
  },
  deleteInvestment({ commit, getters }, { invID, compID }) {
    commit('setStatus', 'loading', { root: true })
    commit('setLoading', true, { root: true })
    commit('clearError', null, { root: true })

    let compRef = firebase.firestore().collection('users').doc(getters.user.uid).collection('companies').doc(compID)

    return compRef.update({
      ['investments.' + invID]: firebase.firestore.FieldValue.delete()
    })
      .then(response => {
        commit('removeInvestment', invID)
        commit('setStatus', 'success', { root: true })
        commit('clearError', null, { root: true })
        commit('setLoading', false, { root: true })
      }
      ).catch((error) => {
        commit('setStatus', 'failure', { root: true })
        commit('setError', error.message, { root: true })
        commit('setLoading', false, { root: true })
      })
  },
  createInvestment({ commit, getters, rootGetters }, payload) {
    commit('setStatus', 'loading', { root: true })
    commit('setLoading', true, { root: true })
    commit('clearError', null, { root: true })

    //Instead of firebase query for matching companies, do client-side search for faster execution
    let matchCompanyName = rootGetters.matchCompany(payload.company_name)
    if (matchCompanyName) { //Company already exists in store and db
      var newRef = firebase.firestore().collection('users').doc(getters.user.uid).collection('companies').doc(matchCompanyName.id)
      var companyData = {}
    }
    else {
      var newRef = firebase.firestore().collection('users').doc(getters.user.uid).collection('companies').doc()
      var companyData = { ...constants.newCompany }
    }

    let newInvestment = {
      company_name: payload.company_name,
      investments: {}
    }

    newInvestment['investments'][payload.id] = {
      ...constants.primaryFields,
      converted: { ...constants.converted },
      dealTerms: { ...constants.dealTerms },
      taxes: { ...constants.taxFields },
      dueDiligence: { ...constants.dueDiligence },
      exitEvent: { ...constants.exitEvent },
      customFields: { ...constants.customFields }
    }

    Object.assign(newInvestment['investments'][payload.id], {  // company_name: payload.company_name, //Duplicate info for higher-level company
      amount: payload.amount,
      estValue: payload.estValue,
      date: payload.date,
      portal: payload.portal,
      security: payload.security,
      regType: 'Reg CF' // Default for all investments set to Reg CF
    })

    let thisCompID = newRef.id

    const company = Object.assign({}, companyData, newInvestment) //Company data is either default or blank

    return newRef.set(company, { merge: true }).then((response) => {
      //Update the Vuex store

      //TODO: these two lines are A culprit - still something weird happening at login with setinvest/setcompanies?
      const { investments, ...noInvestments } = company //Spread operator to destructure company for Vuex store

      commit('createInvestment', { ...newInvestment['investments'][payload.id], id: payload.id, company_name: payload.company_name, compID: thisCompID })
      // commit('createInvestment', { id: payload.id, company_name: payload.company_name })

      if (!matchCompanyName) { //Only create company if it doesn't already exist in Vuex and Firestore
        commit('createCompany', { ...noInvestments, id: thisCompID, industry: "Not Assigned" })
      }

      commit('setStatus', 'success')
      commit('clearError')
      commit('setLoading', false, { root: true })
    }).catch((error) => {
      commit('setStatus', 'failure', { root: true })
      commit('setError', error.message, { root: true })
      commit('setLoading', false, { root: true })
    })
  },
  updateInvestment({ commit, getters }, payload) {
    commit('setStatus', 'loading', { root: true })
    commit('setLoading', true, { root: true })
    commit('clearError', null, { root: true })

    // This is still needed for Firebase update
    const updateObj = {};

    Object.keys(payload).forEach(function (key) {
      if (key !== payload.id) { updateObj[key] = payload[key] }
    });

    //Update Firebase
    let compRef = firebase.firestore().collection('users').doc(getters.user.uid).collection('companies').doc(payload.compID)

    return compRef.set({
      investments: {
        [payload.id]: { ...updateObj }
      }
    }, { merge: true })
      .then(response => {
        // Update store
        commit('updateInvestment', { ...updateObj, id: payload.id })
        commit('setStatus', 'success', { root: true })
        commit('clearError', null, { root: true })
        commit('setLoading', false, { root: true })
      }
      ).catch((error) => {
        commit('setStatus', 'failure', { root: true })
        commit('setError', error.message, { root: true })
        commit('setLoading', false, { root: true })
      })
  },
  loadInvestments({ commit, getters }) {
    //Initial load of all investments and companies from Firebase
    return firebase.firestore().collection('users').doc(getters.user.uid).collection('companies').get()
      .then((querySnapshot) => {
        const investments = []
        const companies = []

        querySnapshot.forEach((doc) => { //Loop through each company
          let loadedCompany = doc.data()
          const { investments: coInvestments, ...thisCompany } = loadedCompany
          companies.push({ ...thisCompany, id: doc.id })
          Object.entries(coInvestments).forEach(([thisId, thisInv]) => { //Loop through investments in each company
            investments.push({ ...thisInv, id: thisId, company_name: loadedCompany.company_name, compID: doc.id })
          })
        })

        commit('setLoadedInvestments', investments)
        commit('setLoadedCompanies', companies, { root: true }) //This was fine - didnt prevent dup
      })
      .catch(
        (error) => {
          console.log(error)
        }
      )
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
