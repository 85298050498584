<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].background}">
    <TheNavbar />
    <v-content>
      <router-view />
    </v-content>
  </v-app>
</template>

<script>
import TheNavbar from "./components/TheNavbar.vue";
export default {
  components: { TheNavbar },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    }
  }
};
</script>
