import Vue from 'vue'
import Router from 'vue-router'
import { store } from '@/store/store'

Vue.use(Router)
// Vue.use(firebase)

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('./views/Home.vue')
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: () => import('./views/Dashboard.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/account',
      name: 'Account',
      component: () => import('./views/Account.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/strategy',
      name: 'Strategy',
      component: () => import('./views/Strategy.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/investments',
      name: 'Investments',
      component: () => import('./views/Investments.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/companies/:id',
      name: 'EditCompany',
      props: true,
      component: () => import('./views/EditCompany.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/investments/:id',
      name: 'EditInvestment',
      props: true,
      component: () => import('./views/EditInvestment.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/about',
      name: 'About',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    },
    {
      path: '/faq',
      name: 'FAQ',
      component: () => import(/* webpackChunkName: "faq" */ './views/Faq.vue')
    },
    {
      path: '/register',
      name: 'Register',
      component: () => import('./views/Register.vue'),
      meta: {
        requiresGuest: true
      }
    },
    {
      path: '/signin',
      name: 'Signin',
      component: () => import('./views/Signin.vue'),
      meta: {
        requiresGuest: true
      }
    },
    {
      path: '/forgotpassword',
      name: 'ForgotPassword',
      component: () => import('./views/ForgotPassword.vue'),
      meta: {
        requiresGuest: true
      }
    },
    {
      path: '/terms',
      name: 'Terms',
      component: () => import('./views/Terms.vue'),
      meta: {
        requiresGuest: false
      }
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: () => import('./views/Privacy.vue'),
      meta: {
        requiresGuest: false
      }
    }
  ]
})

// Nav Guards
router.beforeEach((to, from, next) => {
  let currentUser = store.getters.isLoggedIn

  // Check for requiredAuth guard
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if NOT logged in
    if (!currentUser) {
      // Go to login
      next({
        path: '/signin',
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      // Proceed to route
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresGuest)) {
    //TODO: Currently doesn't function as cookies aren't stored - just logs user out if manually enter URL
    // Check if logged in
    if (currentUser) {
      // Go to dashboard
      next({
        path: '/dashboard',
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      // Proceed to route
      next()
    }
  } else {
    // Proceed to route
    next()
  }
})

export default router
