import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage';
import router from '../../router'
import constants from '@/constants.js'

const initialState = () => ({
  isLoggedIn: null,
  user: {
    uid: null,
    firstName: null,
    lastName: null,
    avatarURL: null,
    investorType: { value: null, text: null },
    netWorth: null,
    income: null,
    userAge: { value: null, text: null },
    email: null,
    //For strategy page
    targetIRR: null,
    targetTime: null,
    failureRate: null,
    portfolioAlloc: null,
    totalDeals: null,
    annualDeals: null,
    firstLogin: null
  }
})

const state = {
  initialState: { ...initialState() },
  ...initialState()
}

const getters = {
  user(state) {
    return state.user
  },
  isLoggedIn(state) {
    return state.isLoggedIn
  },
  avatarURL(state) {
    return state.user.avatarURL
  },
  firstName(state) {
    return state.user.firstName
  },
  lastName(state) {
    return state.user.lastName
  },
  userAge(state) {
    return state.user.userAge
  },
  netWorth(state) {
    return state.user.netWorth
  },
  income(state) {
    return state.user.income
  },
  investorType(state) {
    return state.user.investorType
  },
  targetIRR(state) {
    return state.user.targetIRR
  },
  targetTime(state) {
    return state.user.targetTime
  },
  failureRate(state) {
    return state.user.failureRate
  },
  portfolioAlloc(state) {
    return state.user.portfolioAlloc
  },
  totalDeals(state) {
    return state.user.totalDeals
  },
  annualDeals(state) {
    return state.user.annualDeals
  },
  isFirstLogin(state) {
    return state.user.firstLogin
  },
  email(state) {
    return state.user.email
  }
}

const mutations = {
  setUser(state, payload) {
    Object.assign(state.user, payload) // Reactive caveat not needed if not adding new props, i.e state.user = Object.assign({}, state.user, payload)
  },
  removeUser(state) {
    state.user = null
  },
  setLoggedIn(state, payload) {
    state.isLoggedIn = payload
  },
  resetUser(s) {
    const initial = { ...initialState() }
    Object.keys(initial).forEach(key => { s[key] = initial[key] })
  },
  //TODO: Simplify all the below into one mutation by doing similar to investments.store update
  setFirstName(state, firstName) {
    state.user.firstName = firstName
  },
  setLastName(state, lastName) {
    state.user.lastName = lastName
  },
  setAvatarURL(state, avatarURL) {
    state.user.avatarURL = avatarURL
  },
  setAge(state, userAge) {
    state.user.userAge = userAge
  },
  setNetWorth(state, netWorth) {
    state.user.netWorth = netWorth
  },
  setIncome(state, income) {
    state.user.income = income
  },
  setInvestorType(state, investorType) {
    state.user.investorType = investorType
  },
  setTargetIRR(state, targetIRR) {
    state.user.targetIRR = targetIRR
  },
  setTargetTime(state, targetTime) {
    state.user.targetTime = targetTime
  },
  setFailureRate(state, failureRate) {
    state.user.failureRate = failureRate
  },
  setPortfolioAlloc(state, portfolioAlloc) {
    state.user.portfolioAlloc = portfolioAlloc
  },
  setTotalDeals(state, totalDeals) {
    state.user.totalDeals = totalDeals
  },
  setAnnualDeals(state, annualDeals) {
    state.user.annualDeals = annualDeals
  },
  setFirstLogin(state, isFirst) {
    state.user.firstLogin = isFirst
  }
}

const actions = {
  loadUser({ commit, dispatch, getters }, userPayload) {
    // Load all user data once - this gets called multiple times on live Beta site on first social signin
    return firebase.firestore().collection('users').doc(userPayload.uid).get().then(function (doc) {
      if (doc.exists) {
        //This gets called multiple times on logout?
        commit('setUser', doc.data())

        //Load investments and companies store
        dispatch('loadInvestments', null, { root: true })

      } else {
        // doc.data() will be undefined in this case
        // First time logging in via social
        commit('setFirstLogin', true)

        if (getters.loadedCompanies[1] === undefined) //Prevent from firing if already completed once - does this multiple times with Social Login
        {
          // Create new Firestore user document with UID as document ID
          const initial = { ...initialState().user }
          initial.email = userPayload.email
          initial.uid = userPayload.uid
          initial.avatarURL = userPayload.avatarURL

          //isFirstLogin not stored in Firebase - use userPayload below for initial setUser so it doesn't reset to false
          let newRef = firebase.firestore().collection('users').doc(userPayload.uid)

          newRef.set(initial).then((snapshot) => { commit('setUser', userPayload) }).catch((error) => {
            console.log(error)
          })

          // Set initial dummy investments for new user
          let dummy1 = constants.dummy1
          let dum1Ref = newRef.collection('companies').doc()
          dum1Ref.set(dummy1).then((snapshot) => {
            let { investments: dumInv, ...dumComp } = dummy1
            let thisID = Object.keys(dumInv)[0]
            dumInv = Object.values(dumInv)[0]
            commit('createCompany', dumComp, { root: true })
            commit('createInvestment', { ...dumInv, company_name: dumComp.company_name, id: thisID, compID: dum1Ref.id }, { root: true })
          }).catch((error) => {
            console.log(error)
          })

          let dummy2 = constants.dummy2
          let dum2Ref = newRef.collection('companies').doc()
          dum2Ref.set(dummy2).then((snapshot) => {
            let { investments: dumInv, ...dumComp } = dummy2
            let thisID = Object.keys(dumInv)[0]
            dumInv = Object.values(dumInv)[0]
            commit('createCompany', dumComp, { root: true })
            commit('createInvestment', { ...dumInv, company_name: dumComp.company_name, id: thisID, compID: dum2Ref.id }, { root: true })
          }).catch((error) => {
            console.log(error)
          })
        }
      }
    }).catch(function (error) {
      console.log('Error getting user data document:', error)
    })
  },
  signUpAction({ commit }, payload) {
    commit('setStatus', 'loading', { root: true })
    commit('setLoading', true, { root: true })
    commit('clearError', null, { root: true })

    return firebase.auth().createUserWithEmailAndPassword(payload.email, payload.password).then((response) => {
      let newUser = {
        uid: response.user.uid,
        email: response.user.email,
        // firstName: response.user.displayName,
        // avatarURL: response.user.photoURL,
        firstLogin: true
      }
      commit('setUser', newUser)

      // Create new Firestore user document with UID as document ID
      const initial = { ...initialState().user }
      initial.email = payload.email
      initial.uid = response.user.uid

      let newRef = firebase.firestore().collection('users').doc(response.user.uid)
      newRef.set(initial).then((snapshot) => {
      }).catch((error) => {
        console.log(error)
      })

      // Set initial dummy investments for new user
      let dummy1 = constants.dummy1

      let dum1Ref = newRef.collection('companies').doc()
      dum1Ref.set(dummy1).then((snapshot) => {
        let { investments: dumInv, ...dumComp } = dummy1
        let thisID = Object.keys(dumInv)[0]
        dumInv = Object.values(dumInv)[0]
        commit('createCompany', dumComp, { root: true })
        commit('createInvestment', { ...dumInv, company_name: dumComp.company_name, id: thisID, compID: dum1Ref.id }, { root: true })
      }).catch((error) => {
        console.log(error)
      })

      let dummy2 = constants.dummy2
      let dum2Ref = newRef.collection('companies').doc()
      dum2Ref.set(dummy2).then((snapshot) => {
        let { investments: dumInv, ...dumComp } = dummy2
        let thisID = Object.keys(dumInv)[0]
        dumInv = Object.values(dumInv)[0]
        commit('createCompany', dumComp, { root: true })
        commit('createInvestment', { ...dumInv, company_name: dumComp.company_name, id: thisID, compID: dum2Ref.id }, { root: true })
      }).catch((error) => {
        console.log(error)
      })

      commit('setStatus', 'success', { root: true })
      commit('setLoggedIn', true) // TODO: Better way of doing this? Auth state observer? onAuthStateChanged is being called after anyway
      commit('clearError', null, { root: true })
      commit('setLoading', false, { root: true })

      router.push('/dashboard')
    }).catch((error) => {
      commit('setStatus', 'failure', { root: true })
      commit('setError', error.message, { root: true })
      commit('setLoading', false, { root: true })
    })
  },
  signInAction({ commit, dispatch }, payload) {
    commit('setStatus', 'loading', { root: true })
    commit('setLoading', true, { root: true })
    commit('clearError', null, { root: true })
    return firebase.auth().signInWithEmailAndPassword(payload.email, payload.password).then((response) => {
      dispatch('resetAll', null, { root: true }).then(() => {
        dispatch('fetchCreds').then(() => {
          commit('setLoggedIn', true)
          commit('setStatus', 'success', { root: true })
          commit('clearError', null, { root: true })
          commit('setLoading', false, { root: true })

          router.push('/dashboard').then({}).catch(err => {
            console.log('Error while logging in: ' + err)
            commit('setStatus', 'failure', { root: true })
            commit('setError', err.message, { root: true })
            commit('setLoading', false, { root: true })
          });

        }).catch((error) => {
          commit('setStatus', 'failure', { root: true })
          commit('setError', error.message, { root: true })
          commit('setLoading', false, { root: true })
        })
      }).catch((error) => {
        commit('setStatus', 'failure', { root: true })
        commit('setError', error.message, { root: true })
        commit('setLoading', false, { root: true })
      })
    }).catch((error) => {
      commit('setStatus', 'failure', { root: true })
      commit('setError', error.message, { root: true })
      commit('setLoading', false, { root: true })
    })
  },
  signOutAction({ commit, dispatch }) {
    commit('setStatus', 'loading', { root: true })
    commit('setLoading', true, { root: true })
    commit('clearError', null, { root: true })
    return firebase.auth().signOut()
      .then(() => {
        dispatch('resetAll', null, { root: true })
        commit('setStatus', 'success', { root: true })
        commit('clearError', null, { root: true })
        commit('setLoading', false, { root: true })
        commit('setLoggedIn', false) // TODO: Better way of doing this? Auth state listener?
        window.sessionStorage.clear();
        router.push('/signin')
      })
      .catch((error) => {
        commit('setStatus', 'failure', { root: true })
        commit('setError', error.message, { root: true })
        commit('setLoading', false, { root: true })
      })
  },
  googleSignInAction({ commit, dispatch, getters }, payload) {
    commit('setStatus', 'loading', { root: true })
    commit('setLoading', true, { root: true })
    commit('clearError', null, { root: true })

    const provider = new firebase.auth.GoogleAuthProvider();

    return firebase
      .auth()
      .signInWithPopup(provider)
      .then((response) => {
        dispatch('resetAll', null, { root: true }).then(() => {
          dispatch('fetchCreds').then(() => {
            commit('setLoggedIn', true) // TODO: Better way of doing this? Auth state listener?
            commit('setStatus', 'success', { root: true })
            commit('clearError', null, { root: true })
            commit('setLoading', false, { root: true })
            router.push('/dashboard').then({}).catch(err => {
              console.log('Error while logging in: ' + err)
              commit('setStatus', 'failure', { root: true })
              commit('setError', err.message, { root: true })
              commit('setLoading', false, { root: true })
            });
          })
        })
      }).catch((error) => {
        commit('setStatus', 'failure', { root: true })
        commit('setError', error.message, { root: true })
        commit('setLoading', false, { root: true })
      })
  },
  fetchCreds({ commit, dispatch, getters }) {
    const removeListener = firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        // Get Firebase auth credentials for user
        let thisUser = {
          uid: user.uid,
          email: user.email,
          avatarURL: user.photoURL
        }
        // Get all user meta from Firestore
        dispatch('loadUser', thisUser).then(() => { removeListener() })
      }
    })
    //TODO: not sure this is the best way. What if this gets called before you can log in?
    // removeListener(); //Remove the onauthstatechanged listener so it doesn't fire multiple times if logging out then back in
  },
  updateUserProfile({ commit, getters }, userProfile) {
    return firebase.firestore().collection('users').doc(getters.user.uid).set(userProfile, { merge: true }).then(() => {
      if (userProfile.firstName) {
        commit('setFirstName', userProfile.firstName)
      }
      if (userProfile.lastName) {
        commit('setLastName', userProfile.lastName)
      }
      if (userProfile.userAge) {
        commit('setAge', userProfile.userAge)
      }
      if (userProfile.avatarURL) {
        commit('setAvatarURL', userProfile.avatarURL)
      }
    }).catch((err) => {
      console.log('Error: ' + err)
    })
  },
  updateInvestorProfile({ commit, getters }, investorProfile) {
    // TODO: this method vs. the way I update it in investments.store - create updateObj then all at once
    return firebase.firestore().collection('users').doc(getters.user.uid).set(investorProfile, { merge: true }).then(() => {
      if (investorProfile.netWorth) {
        commit('setNetWorth', investorProfile.netWorth)
      }
      if (investorProfile.income) {
        commit('setIncome', investorProfile.income)
      }
      if (investorProfile.investorType) {
        commit('setInvestorType', investorProfile.investorType)
      }
      if (investorProfile.targetIRR) {
        commit('setTargetIRR', investorProfile.targetIRR)
      }
      if (investorProfile.targetTime) {
        commit('setTargetTime', investorProfile.targetTime)
      }
      if (investorProfile.failureRate) {
        commit('setFailureRate', investorProfile.failureRate)
      }
      if (investorProfile.portfolioAlloc) {
        commit('setPortfolioAlloc', investorProfile.portfolioAlloc)
      }
      if (investorProfile.totalDeals) {
        commit('setTotalDeals', investorProfile.totalDeals)
      }
      if (investorProfile.annualDeals) {
        commit('setAnnualDeals', investorProfile.annualDeals)
      }
    }).catch((err) => {
      console.log('Error: ' + err)
    })
  },
  resetPass({ commit }, emailAddress) {
    commit('setStatus', 'loading', { root: true })
    commit('setLoading', true, { root: true })
    commit('clearError', null, { root: true })
    return firebase.auth().sendPasswordResetEmail(emailAddress)
      .then(function () {
        commit('setStatus', 'success', { root: true })
        commit('clearError', null, { root: true })
        commit('setLoading', false, { root: true })
        commit('setResetPass', null, { root: true })
      })
      .catch((error) => {
        commit('setStatus', 'failure', { root: true })
        commit('setError', error.message, { root: true })
        commit('setLoading', false, { root: true })
      })
  },
  updateAvatarURL({ dispatch, commit, getters }, payload) {
    commit('setStatus', 'loading', { root: true })
    commit('setLoading', true, { root: true })
    commit('clearError', null, { root: true })

    let storageRef = firebase.storage().ref('users/' + getters.user.uid + '/');
    let avatarRef = storageRef.child('Profile_pic')
    //TODO: Cloud function to resize photos to smaller size
    return avatarRef.put(payload).then((snapshot) => {

      snapshot.ref.getDownloadURL()
        .then(downloadURL => {
          let userProfile = {}
          userProfile.avatarURL = downloadURL
          dispatch('updateUserProfile', userProfile)
          commit('setStatus', 'success', { root: true })
          commit('clearError', null, { root: true })
          commit('setLoading', false, { root: true })
        });

    }).catch((err) => {
      console.log(err)
      commit('setStatus', 'failure', { root: true })
      commit('setError', err.message, { root: true })
      commit('setLoading', false, { root: true })
    })
  },
  setFirstLogin({ commit }) {
    commit('setFirstLogin', false)
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
