<template>
  <v-alert :type="text_style" dismissible @input="onClose" :value="true">{{ text }}</v-alert>
</template>

<script>
export default {
  props: ["text", "text_style"],
  methods: {
    onClose() {
      this.$emit("dismissed");
    }
  }
};
</script>
