export const ruleMixin = {
    data: () => ({
        requiredField: [
            v => !!v || "Required",
            //   v => (v && v.length > 1) || "Cannot be blank"
        ],
        dateFormat: [
            // v => 
        ]
        // handleChange(event) {
        //     const amount = event.target.value;

        //     if (!amount || amount.match(/^\d{1,}?$/)) {
        //         this.setState({
        //             [event.target.name]: event.target.value
        //         });
        //     };
        // }
    })
}