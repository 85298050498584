import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: {
        primary: '#005ffe',
        secondary: '#00145a',
        tertiary: '#17c8a5',
        background: '#ffffff'
      },
      dark: {
        primary: '#005ffe',
        secondary: '#00145a',
        tertiary: '#17c8a5'
      },
    }
  }
})
