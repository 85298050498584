const initialState = () => ({
  status: null,
  loading: false,
  error: null,
  snackbar_reset: false
})

const state = {
  initialState: { ...initialState() },
  ...initialState()
}

const mutations = {
  setLoading(state, payload) {
    state.loading = payload
  },
  setStatus(state, payload) {
    state.status = payload
  },
  setError(state, payload) {
    state.error = payload
  },
  clearError(state) {
    state.error = null
  },
  resetErrors(s) {
    const initial = { ...initialState() }
    Object.keys(initial).forEach(key => { s[key] = initial[key] })
  },
  setResetPass(state) {
    state.snackbar_reset = true
  },
  clearResetPass(state) {
    state.snackbar_reset = false
  }
}

const getters = {
  isLoading(state) {
    return state.loading
  },
  status(state) {
    return state.status
  },
  error(state) {
    return state.error
  },
  snackbar_reset(state) {
    return state.snackbar_reset
  }
}

const actions = {
  clearError({ commit }) {
    commit('clearError')
  },
  clearResetPass({ commit }) {
    commit('clearResetPass')
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
