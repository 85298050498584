// Constants used in Store and reused other files

const primaryFields = {
    // id: null, //No longer stored - grab from higher level company object
    // company_name: null, //Now pulled from upper level
    // compID: null, //Used for deleting the investment from a list to pass the doc ref id to Firebase
    amount: null,
    date: null,
    portal: null,
    security: null,
    dealNotes: null,
    updates: null,
    regType: null,
    accountType: null,
    dealFees: null,
    perks: null,
    perkStatus: null,
    dealStatus: null,
    estValue: null,
    //These props must be initialized at the start so Vue sets them as reactive since they depend on v-if
    isSecondary: null,
    isExited: null,
    isFailed: null
}

const dealTerms = {
    earlyBird: null,
    discountRate: null,
    valCap: null,
    premoneyVal: null,
    postmoneyVal: null,
    sharePrice: null,
    numShares: null,
    interestRate: null,
    maturityDate: null,
    proRata: null,
    repurchaseRights: null,
    MFN: null,
    votingRights: null,
    liquidationPreference: null,
    revSharePercent: null,
    revShareFreq: null,
    repaymentCap: null,
    isSecured: null,
    dealTermsNotes: null
};

const dueDiligence = {
    teamRating: null,
    techRating: null,
    tractionRating: null,
    tamRating: null,
    termsRating: null,
    teamNotes: null,
    techNotes: null,
    tractionNotes: null,
    tamNotes: null,
    termsNotes: null
};

const taxFields = {
    //Vue requires these to be initialized to be reactive - used in v-if
    is1045: null,
    purchaseDate: null,
    priorRolloverID: null,
    //QSBS Fields
    domesticCorp: null,
    originalPurchaser: null,
    grossAssets50: null,
    qualifiedBusiness: null,
    activeBusiness: null,
    after2010: null,
    //Section 1244 Stock Fields
    domesticCorS: null,
    acquiredForCash: null,
    under1Mil: null,
    revenueOver50: null,
    //Convertible Note Fields
    periodicPayments: null,
    taxNotes: null
};

const converted = {
    isConverted: false, //initialize null to be reactive
    convertDate: null,
    convertType: null,
    convertPrice: null,
    convertShares: null,
    convertNotes: null
};

const exitEvent = {
    exitType: null,
    exitDate: null,
    exitProceeds: null,
    exitNotes: null,
};

// const companies = [{ // Company details
//     id: null,
//     company_name: null,
//     industry: null,
//     legalName: null,
//     entityType: null,
//     companyURL: null,
//     startupAvatar: null,
//     hardware: null,
//     description: null,
//     founders: {
//         founder1: {
//             name: null,
//             role: null,
//             linkedinURL: null,
//             founderNotes: null
//         },
//         founder2: {
//             name: null,
//             role: null,
//             linkedinURL: null,
//             founderNotes: null
//         }
//     },
//     latestValuation: null,
//     investments: [] //List of investments for each company - typically just 1
// }]

const newCompany = { // Company details for initializing each new investment with minimum null fields to be reactive
    // id: null, //Get id from doc.id instead to put into store and prevent duplication of info
    company_name: null,
    industry: "Not Assigned",
    legalName: null,
    entityType: null,
    companyURL: null,
    startupAvatar: null,
    hardware: null,
    description: null,
    founders: { founder1: {} }, //This could be an issue - spread operator doesn't deep clone
    latestValuation: null,
    investments: [] //List of investments for each company - typically just 1
}

const customFields = {
    KJAs897asdkua98s7d:
    {
        label: "Custom Field 1",
        value: "Value 1"
    }
}

const defaultIndustries = ['AI / Machine Learning', 'AR / VR', 'Ads and Marketing', 'Agriculture', 'Art', 'Auto Tech', 'FinTech', 'Consumer Products', 'Communications', 'Crypto & Blockchain ', 'Data & Analytics', 'Information Technology', 'Education', 'Electronics', 'Energy & CleanTech', 'Enterprise', 'Events', 'Fashion & Apparel', 'Food & Drink', 'Healthcare and MedTech', 'Fitness and Wellness', 'Media & Entertainment', 'Gaming', 'Manufacturing', 'Music', 'Transportation & Logistics', 'Government', 'Security', 'Industrial', 'Materials', 'Real Estate', 'Aerospace', 'Software', 'Social Platform', 'Infrastructure', 'Travel & Tourism', 'Type to add new']

const dummy1 = { // Company details
    id: 'h23j4j28974ASds787a',
    company_name: 'Startup A',
    industry: 'Energy & Cleantech',
    legalName: null,
    entityType: null,
    companyURL: null,
    startupAvatar: null,
    hardware: null,
    description: null,
    latestValuation: null,
    investments: {
        'kjsfdlkjasflKJSDkljas': {
            amount: '500',
            date: '2017-08-11',
            portal: 'WeFunder',
            security: 'SAFE',
            dealNotes: null,
            updates: null,
            regType: null,
            accountType: null,
            dealFees: null,
            perks: null,
            perkStatus: null,
            dealStatus: null,
            estValue: '1250',
            //These props must be initialized at the start so Vue sets them as reactive since they depend on v-if
            isSecondary: null,
            isExited: null,
            isFailed: false,
            customFields: [
                {
                    fieldID: "KJAs897asdkua98s7d",
                    label: "Custom Field 1",
                    value: "Value 1"
                },
                {
                    fieldID: "asf9087gd()*Fa",
                    label: "Custom Field 2",
                    value: "Value 2"
                }
            ],
            converted: { ...converted },
            dealTerms: { ...dealTerms },
            taxes: { ...taxFields },
            dueDiligence: { ...dueDiligence },
            exitEvent: { ...exitEvent }
        }
    }
}

const dummy2 = { // Company details
    id: 'ASd897as98d7987sda98',
    company_name: 'Startup B',
    industry: 'FinTech',
    legalName: null,
    entityType: null,
    companyURL: null,
    startupAvatar: null,
    hardware: null,
    description: null,
    latestValuation: null,
    investments: {
        'POQPodcocZPASodis': {
            amount: '1000',
            date: '2018-02-11',
            portal: 'StartEngine',
            security: 'Common Stock',
            estValue: '1300',
            //These props must be initialized at the start so Vue sets them as reactive since they depend on v-if
            isSecondary: null,
            isExited: null,
            customFields: [
                {
                    fieldID: "KJAs897asdkua98s7d",
                    label: "Custom Field 1",
                    value: "Value 1"
                }
            ],
            converted: { ...converted },
            dealTerms: { ...dealTerms },
            taxes: { ...taxFields },
            dueDiligence: { ...dueDiligence },
            exitEvent: { ...exitEvent }
        }
    }
}

export default {
    primaryFields,
    taxFields,
    dueDiligence,
    dealTerms,
    converted,
    exitEvent,
    customFields,
    defaultIndustries,
    newCompany,
    dummy1,
    dummy2
}