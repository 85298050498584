<template>
  <v-dialog max-width="600px" v-model="dialog">
    <template v-slot:activator="{ on: onDialog }">
      <v-tooltip right v-if="btnText === '+'">
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn class="primary" dark v-on="{...onDialog,...onTooltip}">{{ btnText }}</v-btn>
        </template>
        <span>Add New Investment</span>
      </v-tooltip>
      <v-btn v-else class="primary" dark v-on="onDialog">{{ btnText }}</v-btn>
    </template>
    <v-card>
      <v-card-title>Add Investment</v-card-title>
      <v-card-text>
        <v-form class="px-3" ref="form">
          <v-combobox
            prepend-icon="assignment_ind"
            label="Company Name"
            :items="[...loadedInvestments, {company_name: '(Or type to add new)'}]"
            item-text="company_name"
            item-value="company_name"
            v-model="companyName"
            :rules="requiredField"
            :return-object="false"
            outlined
          />
          <v-text-field
            label="Investment Amount"
            v-model="inv_amount"
            prepend-icon="attach_money"
            type="number"
            onkeypress="return event.charCode != 45"
            :rules="requiredField"
            prefix="$"
            outlined
          />

          <v-combobox
            prepend-icon="business"
            label="Funding Portal"
            :items="[...loadedInvestments,{portal: 'WeFunder'},{portal: 'StartEngine'},{portal: 'Republic'},{portal: '(Or type to add new)'}]"
            item-text="portal"
            item-value="portal"
            v-model="fundingPortal"
            :rules="requiredField"
            :return-object="false"
            outlined
          />
          <v-combobox
            prepend-icon="assignment"
            label="Security Type"
            :items="securityTypes"
            v-model="securityType"
            :rules="requiredField"
            :return-object="false"
            outlined
          />

          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dateFormatted"
                label="Date"
                hint="MM/DD/YYYY format"
                persistent-hint
                prepend-icon="event"
                @blur="date = parseDate(dateFormatted)"
                v-on="on"
                outlined
              />
            </template>
            <v-date-picker v-model="date" no-title @input="menu1 = false" min="1990-01-01" />
          </v-menu>

          <v-spacer />
          <v-btn class="mx-0 mt-3 primary" @click="submit" :loading="isLoading">Add Investment</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import db from "@/fb";
import { mapGetters } from "vuex";
import { ruleMixin } from "../components/mixins/ruleMixin.js";

export default {
  mixins: [ruleMixin],
  props: {
    btnText: {
      default: "Add Investment",
      type: String
    }
  },
  data: vm => ({
    companyName: "",
    fundingPortal: "",
    inv_amount: "",
    securityType: "",
    securityTypes: [
      "SAFE",
      "Common Stock",
      "Preferred Stock",
      "Convertible Note",
      "Revenue Share",
      "(Or type to add new)"
    ],
    date: new Date().toISOString().substr(0, 10),
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    menu1: false,
    valid: true, // This is to prevent length error after form submission reset
    dialog: false
  }),
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        const randID =
          Math.random()
            .toString(36)
            .substring(2, 15) +
          Math.random()
            .toString(36)
            .substring(2, 15);
        const investmentData = {
          company_name: this.companyName,
          amount: this.inv_amount,
          estValue: this.inv_amount,
          date: this.date,
          portal: this.fundingPortal,
          security: this.securityType,
          id: randID
        };

        //Create new investment - new company is created in same investment store action
        this.$store.dispatch("createInvestment", investmentData).then(() => {
          this.dialog = false;
          this.$refs.form.reset();
          this.$emit("investmentAdded");
          this.$router.push("/investments/" + randID);
        });
      } else {
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    ...mapGetters(["isLoading", "error"]),
    loadedInvestments() {
      return this.$store.getters.loadedInvestments;
    }
  },

  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    }
  }
};
</script>
