import Vue from 'vue'
import firebase from 'firebase/app'
import 'firebase/firestore'
import "firebase/analytics";
import firebaseConfig from '@/components/firebaseConfig'

// // Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.analytics();

// alias
Vue.prototype.$analytics = firebase.analytics();

var db = firebase.firestore();

//For Firebase Emulator on local
if (location.hostname === "localhost") {
    db.settings({
        host: "localhost:8080",
        ssl: false
    });
}

// Not required anymore - ensure I don't get warnings when saving dates
// db.settings({ timestampsInSnapshots: true });

export default db
