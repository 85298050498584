import * as firebase from 'firebase/app'
import constants from '@/constants.js'

// Initial state is for resetting state at logout - not needed if I load from database?
const initialState = () => ({
    loadedCompanies: []
})

const state = {
    initialState: { ...initialState() },
    ...initialState()
}

const getters = {
    loadedCompanies(state) {
        return state.loadedCompanies
    },
    loadedCompany(state) {
        return (companyId) => {
            return state.loadedCompanies.find((company) => {
                return company.id === companyId
            })
        }
    },
    matchCompany(state) {
        return (companyName) => {
            return state.loadedCompanies.find((company) => {
                return company.company_name == companyName
            })
        }
    },
    industryData(getters, rootGetters) {
        let companyValues = []
        rootGetters.loadedInvestments.forEach(
            elem => {
                if (!elem.isFailed && !elem.isExited) {
                    if (companyValues[elem['company_name']]) {
                        companyValues[elem['company_name']] += +elem['estValue']
                    }
                    else {
                        companyValues[[elem['company_name']]] = +elem['estValue']
                    }
                }
            }
        )

        let industryMap = [] //Company A: Industry 1, Company B: Industry 2, etc.

        getters.loadedCompanies.forEach(
            elem => {
                if (industryMap[elem['company_name']]) {
                    return
                } else { industryMap[elem['company_name']] = elem['industry'] }
            }
        )

        // Map company names to industries
        let tempList = []
        Object.entries(companyValues).forEach(
            company => {
                if (tempList[industryMap[company[0]]]) { // Reduce to a unique list, [ Industry x: $yy,000, Industry 2: 918023 ]
                    tempList[industryMap[company[0]]] += +company[1].toFixed(2)
                } else { tempList[industryMap[company[0]]] = +company[1].toFixed(2) }
            }
        )

        //In case there are no investments
        if (!tempList) {
            tempList['Add Investments'] = 1;
        }

        let industryData = {}
        industryData.labels = Object.keys(tempList)
        industryData.values = Object.values(tempList)
        return industryData
    }
}

const mutations = {
    setLoadedCompanies(state, payload) {
        state.loadedCompanies.push(...payload)
    },
    createCompany(state, payload) {
        state.loadedCompanies.push(payload)
    },
    updateCompany(state, payload) {
        const company = state.loadedCompanies.find(company => {
            return company.id === payload.id
        })

        Object.keys(payload).forEach(function (key, index) {
            Object.assign(company, payload)
        })
    },
    resetCompanies(s) {
        const initial = { ...initialState() }
        Object.keys(initial).forEach(key => { s[key] = initial[key] })
    }
}

const actions = {
    updateCompany({ commit, getters }, payload) {
        commit('setStatus', 'loading', { root: true })
        commit('setLoading', true, { root: true })
        commit('clearError', null, { root: true })

        // This is still needed for Firebase update
        const updateObj = {};

        Object.keys(payload).forEach(function (key) {
            if (key !== 'id' && key !== 'investments') {
                updateObj[key] = payload[key]
            }
            if (key === 'company_name') {
                commit('updateCompanyName', { id: payload.id, company_name: payload.company_name })
            }
        });

        var compRef = firebase.firestore().collection('users').doc(getters.user.uid).collection('companies').doc(payload.id)

        return compRef.set({ ...updateObj }, { merge: true })
            .then(response => {
                // Update store
                commit('updateCompany', { ...updateObj, id: payload.id })
                commit('setStatus', 'success', { root: true })
                commit('clearError', null, { root: true })
                commit('setLoading', false, { root: true })
            }
            ).catch((error) => {
                commit('setStatus', 'failure', { root: true })
                commit('setError', error.message, { root: true })
                commit('setLoading', false, { root: true })
            })
    },
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}
