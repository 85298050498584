<template>
  <nav>
    <v-snackbar v-model="snackbar" :timeout="3000" top color="success">
      <span>Success! You added a new investment!</span>
      <v-btn @click="snackbar = false" text dark>Close</v-btn>
    </v-snackbar>

    <v-app-bar dark app class="primary" :clipped-left="true">
      <v-toolbar-items>
        <v-list-item
          text
          @click.stop="menuOpen = !menuOpen"
          v-if="isLoggedIn && mini || $vuetify.breakpoint.smAndDown"
          class="mr-5 primary"
        >
          Menu
          <v-icon fab dark>keyboard_arrow_right</v-icon>
        </v-list-item>
        <v-list-item
          text
          @click.stop="menuOpen = !menuOpen"
          v-if="isLoggedIn && !mini && !$vuetify.breakpoint.smAndDown "
          class="mr-5 primary"
        >
          Hide
          <v-icon fab dark>keyboard_arrow_left</v-icon>
        </v-list-item>
      </v-toolbar-items>

      <v-toolbar-title>
        <router-link to="/" align="center">
          <v-img
            src="../assets/images/VentureWallet_Wide_Reversed.png"
            style="max-height:55px;max-width:260px"
          />
        </router-link>
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
          text
          v-for="(item, index) in menuItems"
          :key="index"
          :to="item.route"
          class="white--text"
        >{{ item.title }}</v-btn>
        <v-btn text v-if="!isLoggedIn" to="/signin" dark class="ml-12 primary darken-2">Sign In</v-btn>
        <v-btn dark text v-if="!isLoggedIn" to="/register" class="secondary">Get Started</v-btn>
        <v-btn text @click="logout" v-if="isLoggedIn" class="white--text primary darken-1">
          <span>Log out</span>
          <v-icon right class="white--text">exit_to_app</v-icon>
        </v-btn>
      </v-toolbar-items>

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" class="hidden-md-and-up">more_vert</v-icon>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in menuItems" :key="index" :to="item.route">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="!isLoggedIn" to="/signin">
            <v-list-item-title>Sign In</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="!isLoggedIn" to="/register">
            <v-list-item-title>Get Started</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isLoggedIn" @click="logout">
            <v-list-item-title>Log Out</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
      clipped
      v-model="menuOpen"
      app
      style="background-color:#323232"
      v-if="isLoggedIn"
      :mini-variant="mini"
      :permanent="$vuetify.breakpoint.mdAndUp"
      :temporary="$vuetify.breakpoint.smAndDown"
    >
      <v-container style="background-color:#494949" class="mb-3">
        <v-row v-if="!mini">
          <v-col class="text-center">
            <v-avatar size="100">
              <v-img
                v-if="!avatarURL"
                :src="require('@/assets/images/blank-profile-picture_100px.png')"
              />
              <v-img v-else :src="avatarURL" />
            </v-avatar>
            <p
              v-if="user.firstName"
              class="white--text subheading mt-1"
            >{{ user.firstName }} {{ user.lastName }}</p>
            <p v-else class="white--text subheading mt-1">{{ user.email }}</p>
            <AddInvestment
              @investmentAdded="snackbar = true"
              class="mt-5 mx-5"
              btnText="Add Investment"
            />
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col class="text-center">
            <v-avatar size="50">
              <v-img
                v-if="!avatarURL"
                :src="require('@/assets/images/blank-profile-picture_100px.png')"
              />
              <v-img v-else :src="avatarURL" />
            </v-avatar>
          </v-col>
          <v-col class="text-center px-0">
            <AddInvestment @investmentAdded="snackbar = true" class="mt-5 mx-5" btnText="+" />
          </v-col>
        </v-row>
      </v-container>

      <!-- TODO: Make this simpler via loops https://codepen.io/anon/pen/JeqPmr?editors=1010 -->
      <!-- <v-list-group append-icon dark>
        <template v-slot:activator>
          <v-list-item class="pl-0">
            <v-list-item-action>
              <v-icon slot="prepend" color="white">folder</v-icon>
            </v-list-item-action>

            <v-list-item-title class="white--text">My Startups</v-list-item-title>
          </v-list-item>
          <v-icon slot="append" color="white">expand_more</v-icon>
        </template>
        <v-list-item to="/investments">
          <v-list-item-action class="pl-3">
            <v-icon class="white--text">show_chart</v-icon>
          </v-list-item-action>
          <v-list-item-title class="white--text">Investments</v-list-item-title>
        </v-list-item>

        <v-list-item to="/dealflow">
          <v-list-item-action class="pl-3">
            <v-icon class="white--text">person</v-icon>
          </v-list-item-action>

          <v-list-item-title class="white--text">Deal Flow</v-list-item-title>
        </v-list-item>
        <v-list-item to="/updates">
          <v-list-item-action class="pl-3">
            <v-icon class="white--text">update</v-icon>
          </v-list-item-action>
          <v-list-item-title class="white--text">Updates</v-list-item-title>
        </v-list-item>
        <v-list-item to="/taxes">
          <v-list-item-action class="pl-3">
            <v-icon class="white--text">attach_money</v-icon>
          </v-list-item-action>
          <v-list-item-title class="white--text">Taxes</v-list-item-title>
        </v-list-item>
      </v-list-group>-->
      <!-- Nav links -->
      <v-list>
        <v-list-item v-for="link in links" :key="link.text" router :to="link.route">
          <v-list-item-action>
            <v-tooltip right v-if="mini">
              <template v-slot:activator="{ on }">
                <v-icon class="white--text" v-on="on">{{ link.icon }}</v-icon>
              </template>
              <span>{{ link.tooltip }}</span>
            </v-tooltip>
            <v-icon v-else class="white--text">{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text">{{ link.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-spacer />
        </v-list-item>

        <v-list-item v-if="isLoggedIn" @click="logout">
          <v-list-item-action>
            <v-tooltip right v-if="mini">
              <template v-slot:activator="{ on }">
                <v-icon class="white--text" v-on="on">exit_to_app</v-icon>
              </template>
              <span>Log Out</span>
            </v-tooltip>
            <v-icon v-else class="white--text">exit_to_app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text">Log Out</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import AddInvestment from "./AddInvestment.vue";
import { mapGetters } from "vuex";

export default {
  components: { AddInvestment },
  data() {
    return {
      menuOpen: false,
      links: [
        {
          icon: "dashboard",
          text: "Dashboard",
          route: "/dashboard",
          tooltip: "Dashboard"
        },
        {
          icon: "pie_chart",
          text: "My Strategy",
          route: "/strategy",
          tooltip: "My Strategy"
        },
        {
          icon: "show_chart",
          text: "My Startups",
          route: "/investments",
          tooltip: "My Startups"
        },
        { text: "-------------------" },
        {
          icon: "account_circle",
          text: "Account",
          route: "/account",
          tooltip: "Account"
        }
        // { icon: "credit_card", text: "Billing", route: "/billing" },
        // { icon: "card_giftcard", text: "Refer a Friend", route: "/refer" }
      ],
      menuItems: [
        // {
        //   icon: "",
        //   title: "Pricing",
        //   route: "/pricing"
        // },
        { icon: "", title: "About", route: "/about" },
        {
          icon: "",
          title: "FAQ",
          route: "/faq"
        }
      ],
      snackbar: false
    };
  },
  methods: {
    logout: function() {
      this.$store.dispatch("signOutAction");
    }
  },
  computed: {
    // TODO: Maintain Vuex state, even after page refresh. Is this the right place for this?
    ...mapGetters(["isLoading", "isLoggedIn", "user"]),
    avatarURL: {
      get() {
        return this.$store.getters.avatarURL;
      }
    },
    mini() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return !this.menuOpen;
      } else if (this.$vuetify.breakpoint.mdOnly) {
        return this.menuOpen;
      } else {
        return false;
      }
    }
  }
};
</script>
