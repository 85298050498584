import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// import the auto exporter
import modules from './modules'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'
const secureMode = process.env.NODE_ENV === 'production' // Required for https vs. http deployment

// alternative to export default new Vuex.Store({?
export const store = new Vuex.Store({
  modules, //From modules/index.js, module names are capitalized single word, e.g. companies.store.js = Companies
  plugins: [
    // Used for maintaining Vuex state upon page refresh
    // TODO: uncaught error during login. Also, investments dont stay loaded
    createPersistedState({
      storage: window.sessionStorage
      // getItem: key => ls.get(key),
      // setItem: (key, value) => ls.set(key, value, { expires: 1, secure: secureMode }),
      // removeItem: key => ls.remove(key)
    })
  ],
  strict: debug,
  actions: {
    resetAll({ commit }) {
      // TODO: Seems to reset user, but not investments on logout
      return new Promise((resolve, reject) => {
        commit('resetUser')
        commit('resetInvestments')
        commit('resetCompanies')
        commit('resetErrors')
        resolve()
      }
      )
    }
  }
})
