const firebaseConfig = {
  apiKey: "AIzaSyBC2cpG3aM4JWWP7twwXDYQGm2ix4P9OP0",
  authDomain: "venturewallet-app.firebaseapp.com",
  databaseURL: "https://venturewallet-app.firebaseio.com",
  projectId: "venturewallet-app",
  storageBucket: "venturewallet-app.appspot.com",
  messagingSenderId: "608809179681",
  appId: "1:608809179681:web:29aeb97535a92ffe5fe160",
  measurementId: "G-MET74PTERS"
};

export default firebaseConfig
